import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import "./App.css";
import { useEffect, useState } from "react";
import axios from "axios";

function App(params) {
  
  const URL = "https://citykargo.sistemyazilim.com/service/api/CityKargo/";

  let noResultText = "Sonuç Bulunamadı";
  let loadingText = "Yükleniyor";
  let loading = true;


  const [data, setData] = useState();

  function shiftText(text, shiftAmount) {
    const shiftedChars = text.split('').map(char => {
      const charCode = char.charCodeAt(0);
      const shiftedCode = charCode + shiftAmount;
      return String.fromCharCode(shiftedCode);
    });
    return shiftedChars.join('');
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const un = urlParams.get("un");
    const pw = urlParams.get("pw");
    const brcd = urlParams.get("brcd");

    //Örnek istek : http://localhost:3000/?un=|ckoeqorcp{&pw=sygtv{3456&brcd=S42452:2;635379
    loading = false;
    axios
      .get(`${URL}?un=${un}&pw=${pw}&brcd=${brcd}`)
      .then((response) => {
        setData(response.data.data.packages);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        loading = false;
      });
  }, [loading]);
  return (
    <div className="App">
      <div className="container mx-1">
        <h4 className="">
          {data === undefined
            ? loading
              ? loadingText
              : noResultText
            : data[0].package?.package_details.to_name}
        </h4>
        <div>
          <span className="trackingNo">
            Takip Numarası :{" "}
            {data === undefined
              ? "Yükleniyor..."
              : data[0].package?.package_details.barcode}
          </span>
        </div>

        <div>
          <span className="trackingNo">
            Kargoya Veriliş Tarihi :{" "}
            {data === undefined
              ? "Yükleniyor..."
              : data[0].package?.package_details.start_date}
          </span>
        </div>

        <div>
          Durum :
          <Alert className="cargo-status" key="success" variant="success">
            {data === undefined
              ? "Yükleniyor..."
              : data[0].package?.package_details.status_text}
          </Alert>
        </div>

        <Table responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Nereden</th>
              <th>Nereye</th>
              <th>Güncelleme Tarihi</th>
            </tr>
          </thead>
          <tbody>
            {data !== undefined
              ? data[0].package.package_route.map((item, index) => (
                  <tr key={index}>
                    <td>{index}</td>
                    <td>{item.from_name}</td>
                    <td>{item.to_name}</td>
                    <td>{item.date}</td>
                  </tr>
                ))
              : ""}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default App;
